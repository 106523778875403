import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as mapboxgl from 'mapbox-gl';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { MixpanelService } from 'src/app/lib//services/mixpanel.service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { ILocationGeoIp, IModeString } from 'src/app/lib/interfaces/interface';
import { EmailPipe } from 'src/app/lib/pipes/email.pipe';
import { ApiService } from 'src/app/lib/services/api.service';
import { AuthService } from 'src/app/lib/services/auth.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { selectBandSteeringEditable } from 'src/app/store/configview/config.selectors';
import {
  addNewLocation,
  archiveCurrentLocation,
  bandSteeringSet,
  customerSetFromEmailVerified,
  customerSetFromRename,
  locationNameSet,
  networkModeSet,
  optimizationsAutoModeEnable,
  optimizationsDfsModeSet,
  serviceIdSet,
  serviceLevelSet
} from 'src/app/store/customer-info/customer-info.actions';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { updateLogpullHistory } from 'src/app/store/customer/customer.actions';
import {
  selectCustomer,
  selectCustomerIdentification,
  selectCustomerLoading,
  selectLocationList,
  selectLogpullHistory,
  selectNetworkModeEditable,
  selectNonFullModeCapable,
  selectPipeLocationOnChange,
  selectPipeLocationOnChangeOptimistic
} from 'src/app/store/customer/customer.selectors';
import { selectLocationInternet } from 'src/app/store/polling/polling.selector';

@UntilDestroy()
@Component({
  selector: 'customerinfo',
  templateUrl: './customerinfo.component.html',
  styleUrls: ['./customerinfo.component.scss']
})
export class CustomerinfoComponent implements OnInit, OnDestroy {
  customerLoading$ = this.store.select(selectCustomerLoading);
  backendChangedLocation$ = this.store.pipe(selectPipeLocationOnChange); // contains only values already saved to backend
  optimisticLocation$ = this.store.pipe(selectPipeLocationOnChangeOptimistic); // contains changed data not yet saved to backend
  locationList$ = this.store.select(selectLocationList);
  selectBandSteeringEditable$ = this.store.select(selectBandSteeringEditable({ plume: this.plume }));
  open$ = new BehaviorSubject(false);
  membership$ = combineLatest([
    this.open$,
    this.store
      .select(selectCustomerIdentification)
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
  ]).pipe(
    filter(([opened]) => opened),
    map(([, { locationid }]) => locationid),
    distinctUntilChanged(),
    switchMap(() => this.customerService.membership$())
  );

  updateSubscription: any;
  permissionsSubscription: any;
  internetSubscription: any; // used for membership
  logSubscription: any;
  permissions: any;
  customer$ = this.store.select(selectCustomer);
  internet: any = {};
  wifiNetwork$ = new BehaviorSubject<{ enabled: boolean }>({ enabled: true });
  groups: any = [];
  editAccountId: boolean = false;
  accountId: UntypedFormControl = new UntypedFormControl();
  editServiceId: boolean = false;
  serviceId: UntypedFormControl = new UntypedFormControl();
  editLocationName: boolean = false;
  locationName: UntypedFormControl = new UntypedFormControl();

  showLocationConfiguration: boolean = false;
  showLocationAuthorization: boolean = false;
  showCustomerGroupsModal: boolean = false;
  logpullComplete: boolean = false;
  logpullResponse: any;
  showLogPull: boolean = false;
  showLogPullHistory: boolean = false;
  showNewLocation: boolean = false;
  newLocationValidation: boolean = true;
  newLocation: UntypedFormControl = new UntypedFormControl();
  showMap: boolean = false;
  map: mapboxgl.Map;
  capabilities$ = this.store.select(selectCapabilities);
  nonFullModeCapable$ = this.store.select(selectNonFullModeCapable);
  networkModeEditable$ = this.plume.permissions.pipe(
    switchMap((permissions) => this.store.select(selectNetworkModeEditable({ permissions })))
  );

  profileMode = 'auto';
  profileModeItems = [
    {
      value: 'auto',
      translation: 'newCustomer.profileItems.homePass',
      selected: this.profileMode === 'auto'
    },
    {
      value: 'smallbusiness',
      translation: 'newCustomer.profileItems.workPass',
      selected: this.profileMode === 'smallbusiness'
    }
  ];

  configurationEditable: boolean = false;
  controlMode$ = new BehaviorSubject<{ mode: string; modeRealized: string }>({ mode: '', modeRealized: '' });
  autoOptimizeItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: true,
        marked: location.optimization.autoOptimization === true,
        translation: 'enabled',
        selected: location.optimizations.enable
      },
      {
        value: false,
        marked: location.optimization.autoOptimization === false,
        translation: 'disabled',
        selected: !location.optimizations.enable
      }
    ])
  );
  dfsModeItems$ = combineLatest([
    this.store.pipe(selectPipeLocationOnChangeOptimistic),
    of(this.plume.cloudVersionAbove1_103())
  ]).pipe(
    map(([location, isCorrectCloud]) => {
      const dfsModeItems = [
        { value: 'auto', translation: 'auto', selected: location.optimizations.dfsMode === 'auto' },
        {
          value: 'enable',
          translation: 'enabled',
          marked: location.dfsMode === 'enable',
          selected: location.optimizations.dfsMode === 'enable'
        },
        {
          value: 'disable',
          translation: 'disabled',
          marked: location.dfsMode === 'disable',
          selected: location.optimizations.dfsMode === 'disable'
        },
        {
          value: 'HomeNonDFSChannels',
          translation: 'backhaul',
          marked: location.dfsMode === 'HomeNonDFSChannels',
          selected: location.optimizations.dfsMode === 'HomeNonDFSChannels'
        },
        {
          value: 'usDfs',
          translation: 'usDfs',
          marked: location.dfsMode === 'usDfs',
          selected: location.optimizations.dfsMode === 'usDfs'
        }
      ];

      if (isCorrectCloud) {
        dfsModeItems.push({
          value: 'deviceAware',
          translation: 'deviceAware',
          marked: location.dfsMode === 'deviceAware',
          selected: location.optimizations.dfsMode === 'deviceAware'
        });
      }

      return dfsModeItems;
    })
  );
  bandSteeringItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      { value: 'auto', translation: 'auto', selected: location.bandSteering.mode === 'auto' },
      {
        value: 'enable',
        translation: 'enabled',
        marked: location.bandSteering.enable === true, // ignore undefined
        selected: location.bandSteering.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disabled',
        marked: location.bandSteering.enable === false, // ignore undefined
        selected: location.bandSteering.mode === 'disable'
      }
    ])
  );
  clientSteeringItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      { value: 'auto', translation: 'auto', selected: location.clientSteering.mode === 'auto' },
      {
        value: 'enable',
        translation: 'enabled',
        marked: location.clientSteering.enable,
        selected: location.clientSteering.mode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disabled',
        marked: !location.clientSteering.enable,
        selected: location.clientSteering.mode === 'disable'
      }
    ])
  );
  serviceLevelItems$ = this.optimisticLocation$.pipe(
    map((location) => [
      {
        value: 'fullService',
        translation: 'fullService',
        marked: location.serviceLevel && location.serviceLevel.status === 'fullService',
        selected: location.serviceLevel && location.serviceLevel.status === 'fullService' && !location.serviceLevel.auto
      },
      {
        value: 'basicService',
        translation: 'basicService',
        marked: location.serviceLevel && location.serviceLevel.status === 'basicService',
        selected:
          location.serviceLevel && location.serviceLevel.status === 'basicService' && !location.serviceLevel.auto
      },
      {
        value: 'noService',
        translation: 'noService',
        marked: location.serviceLevel && location.serviceLevel.status === 'noService',
        selected: location.serviceLevel && location.serviceLevel.status === 'noService' && !location.serviceLevel.auto
      },
      {
        value: 'reset',
        translation: 'auto',
        selected: location.serviceLevel ? location.serviceLevel.auto : false
      }
    ])
  );
  wifiRadioItems$ = this.wifiNetwork$.pipe(
    map((wifiNetwork) => [
      { value: true, translation: 'enabled', selected: wifiNetwork.enabled },
      { value: false, translation: 'disabled', selected: !wifiNetwork.enabled }
    ])
  );

  controlModeItems$ = combineLatest([this.optimisticLocation$, this.controlMode$]).pipe(
    map(([location, controlMode]) => [
      {
        value: 'full',
        translation: 'customerinfo.locationConfiguration.full',
        marked: controlMode.modeRealized === 'full',
        selected:
          (location?.controlMode as IModeString)?.mode === 'full' ||
          location?.controlMode === 'full' ||
          controlMode.mode === 'full'
      },
      {
        value: 'monitor',
        translation: 'customerinfo.locationConfiguration.monitor',
        marked: controlMode.modeRealized === 'monitor',
        selected:
          (location?.controlMode as IModeString)?.mode === 'monitor' ||
          location?.controlMode === 'monitor' ||
          controlMode.mode === 'monitor'
      },
      {
        value: 'reduced',
        translation: 'customerinfo.locationConfiguration.reduced',
        marked: controlMode.modeRealized === 'reduced',
        selected:
          (location?.controlMode as IModeString)?.mode === 'reduced' ||
          location?.controlMode === 'reduced' ||
          controlMode.mode === 'reduced'
      },
      {
        value: 'battery',
        translation: 'customerinfo.locationConfiguration.battery',
        marked: controlMode.modeRealized === 'battery',
        selected: location?.controlMode === 'battery' || controlMode.mode === 'battery'
      }
    ])
  );
  networkIdOpened = true;

  logpullHistory: any = null;
  messageFormControl: UntypedFormControl = new UntypedFormControl();
  helper: GeneralHelper = new GeneralHelper();
  showNeighborsReportsModal: boolean = false;
  isFlexAdmin: boolean = false;
  isUprise: boolean = false;
  isExtendedAccess: boolean = false;

  selector: any = {
    loading: true,
    show: false,
    current: 'customerinfo.customerGroups.selectGroupToAdd',
    list: []
  };

  constructor(
    public plume: PlumeService,
    private api: ApiService,
    private auth: AuthService,
    private toast: ToastService,
    private mixpanel: MixpanelService,
    private troubleshooting: TroubleshootingService,
    private modal: ModalService,
    private customerService: CustomerService,
    private store: Store,
    private emailPipe: EmailPipe
  ) {}

  ngOnInit(): void {
    this.update();
    this.updateData();
    this.isFlexAdmin = this.plume.isFlexRole();
    this.isExtendedAccess = this.auth.isExtendedAccess();

    this.store
      .select(selectLogpullHistory)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.logpullHistory = response.map((historyEntry) => ({
          ...historyEntry,
          createdAt: moment.utc(historyEntry.createdAt).local().format('L LT')
        }));
      });
  }

  openPanel(open: boolean): void {
    this.open$.next(open);
  }

  update(): void {
    this.updateSubscription = this.backendChangedLocation$.subscribe((location) => {
      this.isUprise = location.uprise;

      this.getControlMode();

      this.customerService.getGroups$().subscribe((response: any) => {
        if (response) {
          this.groups = response;
        }
      });
    });
  }

  updateData(): void {
    this.internetSubscription = this.store.select(selectLocationInternet).subscribe((response: any) => {
      if (response) {
        this.internet = response;
      }
    });

    this.permissionsSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
      this.configurationEditable = this.permissions.uiFeatures.editLocationConf;
    });
  }

  toLocalDate(d: any): string {
    if (!d) {
      return 'N/A';
    } else {
      return moment.utc(d).local().format('L');
    }
  }

  openNeighborsReportsModal(): void {
    this.showNeighborsReportsModal = !this.showNeighborsReportsModal;
  }

  drawMap(geoIp: ILocationGeoIp): void {
    this.showMap = true;
    this.mixpanel.storeEvent('LOCATION_MAP_SCREEN');

    setTimeout(() => {
      if (document.getElementById('map')) {
        this.map = new mapboxgl.Map({
          container: 'map',
          style: 'https://tiles.stadiamaps.com/styles/alidade_smooth_dark.json',
          zoom: 10,
          center: [geoIp.longitude, geoIp.latitude]
        });

        new mapboxgl.Marker().setLngLat([geoIp.longitude, geoIp.latitude]).addTo(this.map);
      }
    }, 0);
  }

  action(command: string, action: string | boolean): void {
    switch (command) {
      case 'autoOptimize':
        this.store.dispatch(optimizationsAutoModeEnable({ value: action as boolean }));
        break;
      case 'dfsMode':
        this.store.dispatch(optimizationsDfsModeSet({ value: action as any }));
        break;
      case 'bandSteering':
        this.store.dispatch(bandSteeringSet({ value: action as any }));
        break;
      case 'serviceLevel':
        this.store.dispatch(serviceLevelSet({ value: action as any }));
        break;
      case 'wifiRadios':
        this.customerService.enableWifiNetwork$(action as boolean).subscribe((response) => {
          this.wifiNetwork$.next({ ...this.wifiNetwork$.value, ...response });
        });
        break;
      case 'controlMode':
        this.customerService.setControlMode$(action as any).subscribe(
          (response: any) => {
            this.controlMode$.next(response);
            this.mixpanel.storeEvent('CONFIGURATION_CONTROL_MODE', { CONTROLMODE: action });
          },
          (error: any) => {
            this.mixpanel.storeEvent('CONFIGURATION_CONTROL_MODE_ERROR');
            this.toast.error(error.error.error.message, 'header.failed');
            this.controlMode$.next(this.controlMode$.value); // set current value to start new mapping
          }
        );
        break;
    }
  }

  getControlMode(): void {
    this.customerService.getControlMode$().subscribe((response: any) => {
      this.controlMode$.next(response);
    });
  }

  changeEmailVerified(): void {
    this.customer$
      .pipe(
        take(1),
        switchMap((customer) =>
          this.customerService.setCustomerProperties$(customer.id, { emailVerified: !customer.emailVerified })
        )
      )
      .subscribe((customer: any) => {
        this.mixpanel.storeEvent('CHANGE_EMAIL_VERIFIED', { IS_VERIFIED: customer.emailVerified });
        this.toast.success('toast.customerinfo.verifiedMsg', 'toast.customerinfo.verifiedTitle');
        this.store.dispatch(customerSetFromEmailVerified({ customer }));
      });
  }

  changeNetworkMode(networkMode: 'router' | 'bridge' | 'auto'): void {
    this.store.dispatch(networkModeSet({ value: networkMode }));
  }

  sendPasswordReset(): void {
    this.customerService.resetPassword$().subscribe((email) => {
      this.mixpanel.storeEvent('SEND_PASSWORD_RESET', { EMAIL: email });
      this.toast.success('toast.customerinfo.passwordMsg', 'toast.customerinfo.passwordTitle', {
        params: { email: this.emailPipe.transform(email) }
      });
    });
  }

  resendEmailVerified(): void {
    this.customerService.resendEmailVerification$().subscribe((email) => {
      this.mixpanel.storeEvent('SEND_EMAIL_VERIFICATION', { EMAIL: email });
      this.toast.success('toast.customerinfo.verificationMsg', 'toast.customerinfo.verificationTitle', {
        params: { email: this.emailPipe.transform(email) }
      });
    });
  }

  validateServiceId(): boolean {
    const str = this.serviceId.value;
    if (str) {
      const len = this.getUTF8Length(str.trim());
      return len > 0;
    }
    return false;
  }

  enableServiceId(serviceId: string): void {
    this.serviceId.setValue(serviceId);
    this.editServiceId = true;
  }

  confirmServiceId(): void {
    if (this.validateServiceId()) {
      this.editServiceId = false;
      this.store.dispatch(serviceIdSet({ value: this.serviceId.value.trim() }));
    } else {
      this.toast.error('toast.customerinfo.errorNoServiceIdMsg', 'toast.customerinfo.errorNoServiceIdTitle');
    }
  }

  cancelServiceId(): void {
    this.editServiceId = false;
    this.serviceId.reset();
  }

  enableLocationName(name: string): void {
    this.locationName.setValue(name);
    this.editLocationName = true;
  }

  cancelLocationName(): void {
    this.editLocationName = false;
    this.locationName.reset();
  }

  confirmLocationName(): void {
    this.store.dispatch(locationNameSet({ value: this.locationName.value.trim() }));
    this.editLocationName = false;
  }

  enableRename(): void {
    this.customer$.pipe(take(1)).subscribe((customer) => {
      this.accountId.setValue(customer.accountId);
      this.editAccountId = true;
      this.mixpanel.storeEvent('EDIT_ACCOUNT_ID_ENABLE');
    });
  }

  validateAccountId(): boolean {
    const str = this.accountId.value;
    if (str) {
      const len = this.getUTF8Length(str.trim());
      return len > 0;
    }
    return false;
  }

  getUTF8Length(str: string): number {
    let utf8length = 0;
    for (let n = 0; n < str.length; n++) {
      const c = str.charCodeAt(n);
      if (c < 128) {
        utf8length++;
      } else if (c > 127 && c < 2048) {
        utf8length = utf8length + 2;
      } else {
        utf8length = utf8length + 3;
      }
    }
    return utf8length;
  }

  confirmAccountIdRename(): void {
    if (this.validateAccountId()) {
      this.customer$
        .pipe(
          take(1),
          switchMap((oldCustomer) =>
            this.customerService.setCurrentCustomerProperties$({ accountId: this.accountId.value.trim() }).pipe(
              tap((newCustomer) => {
                this.editAccountId = false;
                this.toast.success(
                  'toast.technician.successAccountIdMessage',
                  'toast.technician.successAccountIdTitle'
                );
                this.mixpanel.storeEvent('EDIT_ACCOUNT_ID_SUCCESS', {
                  OLD_ACCOUNT_ID: oldCustomer.accountId,
                  NEW_ACCOUNT_ID: this.accountId.value.trim()
                });

                this.store.dispatch(customerSetFromRename({ customer: newCustomer }));
              }),
              catchError((error: any) => {
                this.editAccountId = false;
                this.mixpanel.storeEvent('EDIT_ACCOUNT_ID_ERROR', {
                  OLD_ACCOUNT_ID: oldCustomer.accountId,
                  NEW_ACCOUNT_ID: this.accountId.value.trim(),
                  ERROR: error.error.error.message
                });

                this.toast.error('toast.technician.errorAccountIdMessage', 'toast.technician.errorAccountIdTitle', {
                  disableTimeOut: true,
                  params: {
                    error: error.error.error.message
                  }
                });
                throw error;
              })
            )
          )
        )
        .subscribe();
    }
  }

  cancelRename(): void {
    this.editAccountId = false;
    this.accountId.reset();
    this.mixpanel.storeEvent('EDIT_ACCOUNT_ID_DISABLE');
  }

  showLocationConfigurationScreen(): void {
    this.customerService
      .getWifiNetwork$()
      .pipe(catchError(() => of({ wifiNetwork: { enabled: false } })))
      .subscribe((response: any) => {
        if (response && response.wifiNetwork) {
          this.wifiNetwork$.next(response.wifiNetwork);
        }
      });
    this.showLocationConfiguration = true;
    this.mixpanel.storeEvent('LOCATION_CONFIGURATION_DIALOG');
  }

  closeLocationConfiguration(): void {
    this.cancelLocationName();
    this.cancelServiceId();
    this.showLocationConfiguration = false;
  }

  showLocationAuthorizationScreen(): void {
    this.showLocationAuthorization = true;
    this.mixpanel.storeEvent('LOCATION_AUTHORIZATION_SCREEN');
  }

  openCustomerGroupsModal(): void {
    this.showCustomerGroupsModal = !this.showCustomerGroupsModal;
  }

  showLogPullScreen(): void {
    this.showLogPull = true;
    this.mixpanel.storeEvent('LOGPULL_MANUAL_DIALOG');
  }

  closeLogpull(): void {
    this.showLogPull = false;
  }

  copied(url: string): void {
    this.mixpanel.storeEvent('LOGPULL_HISTORY_COPY');
    try {
      window.navigator.clipboard.writeText(url);
      this.toast.success(
        'customerinfo.logpullHistory.copyUrlToastMessage',
        'customerinfo.logpullHistory.copyUrlToastTitle'
      );
    } catch (error) {}
  }

  showLogPullHistoryScreen(): void {
    this.showLogPullHistory = true;
    this.mixpanel.storeEvent('LOGPULL_HISTORY_SCREEN');
    this.logpullHistory = null;
    this.store.dispatch(updateLogpullHistory());
  }

  downloadLogpull(logpullUrl: string): void {
    this.mixpanel.storeEvent('LOGPULL_DOWNLOAD');

    this.api.get(logpullUrl, 'awlan').subscribe(
      (response: any) => {
        for (const nodeId of Object.keys(response.podFilenameMap)) {
          const logUrl = response.podFilenameMap[nodeId];
          const headers = {
            responseType: 'blob',
            headers: {
              Accept: 'application/octet-stream',
              'Content-Type': 'application/octet-stream',
              ...this.auth.getHeaders(undefined, true)
            }
          };

          this.api.raw('get', logUrl + '?' + this.auth.getParams(), {}, headers).subscribe(
            (response: any) => {
              const binaryData = [];
              binaryData.push(response);

              const blob = new Blob(binaryData, { type: response.type });
              const filename = logUrl.substring(logUrl.lastIndexOf('/') + 1);

              this.helper.download(blob, filename);
            },
            () => {
              this.toast.error(
                'customerinfo.logpullHistory.downloadErrorToastMessage',
                'customerinfo.logpullHistory.downloadErrorToastTitle',
                { params: { nodeId } }
              );
            }
          );
        }
      },
      () => {
        this.toast.error(
          'customerinfo.logpullHistory.awlanErrorToastMessage',
          'customerinfo.logpullHistory.downloadErrorToastTitle'
        );
      }
    );
  }

  newLocationScreen(): void {
    this.showNewLocation = true;
    this.mixpanel.storeEvent('ADD_NEW_LOCATION_DIALOG');
  }

  addNewLocation(): void {
    if (this.newLocation.value) {
      this.newLocationValidation = true;
      this.store.dispatch(addNewLocation({ name: this.newLocation.value, profile: this.profileMode }));
      this.closeNewLocation();
    } else {
      this.newLocationValidation = false;
    }
  }

  closeNewLocation(): void {
    this.newLocationValidation = true;
    this.showNewLocation = false;
    this.newLocation.reset();
  }

  archiveLocation(): void {
    if (!this.isUprise) {
      this.modal
        .showDialog('customerinfo.modal.message', 'customerinfo.modal.title', {
          buttons: [
            { style: 'tertiary light', value: 'customerinfo.modal.cancel' },
            { style: 'super-primary', value: 'customerinfo.modal.archive' }
          ]
        })
        .subscribe((response: any) => {
          if (response.item?.value === 'customerinfo.modal.archive') {
            this.store.dispatch(archiveCurrentLocation());
          }
        });
    }
  }

  formatDate(date: string): string {
    if (date) {
      return moment.utc(date).local().format('L LT');
    } else {
      return 'onboardingStatus.onboardingNotComplete';
    }
  }

  ngOnDestroy(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }

    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }

    if (this.logSubscription) {
      this.logSubscription.unsubscribe();
    }

    if (this.internetSubscription) {
      this.internetSubscription.unsubscribe();
    }
  }
}
